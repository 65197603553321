<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h3 class="fs-6 fs-sm-1">
            <mat-icon class="align-middle mb-1">barcode_reader</mat-icon> Dashboard P&P
          </h3>
        </div>
        <div class="col-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="index.html"><mat-icon color="accent">barcode_reader</mat-icon></a>
            </li>
            <li class="breadcrumb-item">Dashboard P&P</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
      <div class="row">
          <div class="col-sm-12">
              <div class="card">
                  <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Campaña</mat-label>
                                <mat-select [(value)]="selectedValue">
                                    <mat-option *ngFor="let campaign of campaignList" [value]="campaign.id" (onSelectionChange)="onSelected(campaign.id);" >
                                        {{ campaign.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="offset-lg-4 offset-md-4 col-lg-4 col-md-4">
                            <mat-form-field class="w-100" color="accent">
                                <mat-label>
                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                  <input matInput placeholder="Ej. POP AGOSTO 2023" #input>
                            </mat-form-field>
                        </div>
                    </div>

                     
                      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                          <mat-progress-bar color="accent" mode="indeterminate">
                          </mat-progress-bar>
                      </div>
                      <div class="mat-elevation-z8 table-responsive">
                          <table mat-table [dataSource]="dataSource" matSort class="w-100">
                  
                              <ng-container matColumnDef="id">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                  <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                              </ng-container>
                  
                              <ng-container matColumnDef="nombre">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                  <td mat-cell *matCellDef="let row"> <strong>{{row.nombre}}</strong> </td>
                              </ng-container>
  
                              <ng-container matColumnDef="sku">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
                                  <td mat-cell *matCellDef="let row"> {{row.sku}} </td>
                              </ng-container>
                              
                              <ng-container matColumnDef="cantidad">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Total </th>
                                  <td mat-cell *matCellDef="let row"> {{row.cantidad}} </td>
                              </ng-container>
                              
                              <ng-container matColumnDef="packed">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Empacadas </th>
                                  <td mat-cell *matCellDef="let row"> {{row.packed}} </td>
                              </ng-container>
  
                              <ng-container matColumnDef="omitted">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Omitidos </th>
                                  <td mat-cell *matCellDef="let row"> {{row.omitted}} </td>
                              </ng-container>
  
                              <ng-container matColumnDef="missed">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Restantes </th>
                                  <td mat-cell *matCellDef="let row"> {{row.missed}} </td>
                              </ng-container>
  
                              <ng-container matColumnDef="actions">
                                  <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                  <td mat-cell *matCellDef="let row" class="text-center" (click)="$event.stopPropagation()">
                                      <button mat-icon-button matTooltip="Gráficas" [matTooltipPosition]="'above'" color="accent">
                                          <mat-icon>bar_chart</mat-icon>
                                      </button>
                                  </td>
                              </ng-container>
                  
                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                              <tr mat-row class="cursor-pointer" *matRowDef="let row; columns: displayedColumns;" ></tr>                
                              <tr class="mat-row" *matNoDataRow>
                                  <td class="mat-cell" colspan="6">
                                      <span *ngIf="materialListPNP.length == 0;else notFound">No hay información para mostrar</span>
                                      <ng-template #notFound>
                                          No existe información que coincida con "{{input.value}}" 
                                      </ng-template>
                                  </td>
                              </tr>
                          </table>
                          <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>