import { Injectable } from '@angular/core';
import { MainService } from './main.service';
import { map } from 'rxjs';
import { FileModel } from '../models/campaigns.model';


@Injectable({
  providedIn: 'root',
})
export class GoogleDriveService {
  constructor(
    private main: MainService
  ) {}

  getFiles() {
    return this.main.getRequest("/campaign/get-files").pipe(
      map((resp: any) => {
        let fileList: Array<FileModel> = [];
        if(resp.message == "Success") {          
          resp.data.forEach((e:any) => {
            fileList.push(e);
          });
        }     
        return fileList;
      })
    );
  }
}