import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidatorsService } from 'src/app/services/custom-validators.service';
import { UsersService } from 'src/app/services/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users-edit-password',
  templateUrl: './users-edit-password.component.html',
  styleUrls: ['./users-edit-password.component.scss']
})
export class UsersEditPasswordComponent {
  hidePass = true;
  hidePassConfirm = true;

  formPassword!: FormGroup;
  isUserLogged!: boolean;

  constructor(
    private userS: UsersService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private customVal: CustomValidatorsService,
  ) {
    this.isUserLogged = this.data.id_user == this.userS.getUser().id;
    this.createFormPassword();
  }

  ngOnInit(): void {
  }

  createFormPassword() {
    this.formPassword = this.fb.group({
      pass: ['', [Validators.required]],
      passConfirm: ['', [Validators.required]],
      ch_pass: []
    },{
      validators: this.customVal.samePasswords("pass", "passConfirm")
    });
  }

  changePassword() {
    if(this.formPassword.invalid) { return; }
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.formPassword.controls["ch_pass"].setValue(1);
    this.userS.editUser(this.formPassword.value, this.data.id_user).subscribe(
      (result:any) => {
        if(result.message == "Success")        
        Swal.fire({
          icon: 'success',
          title: 'Guardado',
          text: 'La contraseña fue actualizada de manera correcta!',
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then(() => {
        });
      },
      (err:any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }
}
