<div class="modal-header">
    <h5 class="pt-4 ps-4">Nuevo usuario</h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <span>Captura la información del nuevo usuario:</span>
    <form autocomplete="off" [formGroup]="formUser" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Email</mat-label>
                    <mat-icon matSuffix>alternate_email</mat-icon>
                    <input matInput formControlName="email">
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Selecciona perfil</mat-label>
                    <mat-select formControlName="id_rol">
                        <mat-option *ngFor="let rol of rolesList" [value]="rol.id">{{rol.rol}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Password</mat-label>
                    <mat-icon matSuffix (click)="hideEmail = !hideEmail" role="button">{{hideEmail ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hideEmail ? 'password' : 'text'" formControlName="pass" maxlength="30">
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Confirma password</mat-label>
                    <mat-icon matSuffix (click)="hideEmailConfirm = !hideEmailConfirm" role="button">{{hideEmailConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hideEmailConfirm ? 'password' : 'text'" formControlName="passConfirm" maxlength="30">
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button (click)="resetForm()">Limpiar <mat-icon>delete_outline</mat-icon></button>
    <button mat-flat-button class="bg-success text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>