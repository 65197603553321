<div class="modal-header">
    <h5 class="pt-4 ps-4"><mat-icon class="align-middle me-3" color="accent">cloud_upload</mat-icon>Selecciona un archivo</h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
        <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
    </div>
    <div class="file-content">
        <div class="container file-manager">
            <div class="row files ">
                <div *ngFor="let file of fileList" class="col-3 file-box mb-3 me-2" (click)="selectFile(file)">
                    <div class="file-top">
                        <img style="width: 70px;" src="../../../../../assets/images/google-sheets-icon.png" alt="">
                    </div>
                    <div class="file-bottom">
                        <h6 class="text-center">{{file.name}}</h6>
                        <!-- <a target="_blank" href="https://docs.google.com/spreadsheets/d/{{file.id}}"> Vista previa</a> -->
                    </div>
                </div>
            </div>    
        </div>  
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
</mat-dialog-actions>