<div class="modal-header">
    <h5 class="pt-4 ps-4"><mat-icon class="align-middle" color="accent">bar_chart</mat-icon> Dashboard <strong>{{campaing}}</strong><span class="badge text-uppercase ms-4" [ngClass]="status == 'activa' ? 'badge-info' : 'badge-success'">{{status}}</span></h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <h1 class="mt-3 ms-4 h5"><mat-icon class="align-middle">inventory_2</mat-icon> Total de cajas: <strong>{{totalBoxes}}</strong></h1>
      <div id="chart">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [labels]="chartOptions.labels"
          [responsive]="chartOptions.responsive"
        ></apx-chart>
      </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Cerrar <mat-icon>close</mat-icon></button>
</mat-dialog-actions>