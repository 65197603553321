<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6 d-flex align-items-center">
        <h3 class="fs-6 fs-sm-1">
          <mat-icon class="align-middle mb-1">barcode_reader</mat-icon> Dashboard P&P
        </h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="index.html"><mat-icon color="accent">barcode_reader</mat-icon></a>
          </li>
          <li class="breadcrumb-item">Dashboard P&P</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <div class="float-end">
                            <button [matMenuTriggerFor]="menuFilterActive" mat-raised-button color="primary" class="mt-2 mb-2 ms-4 text-white">
                                    Campañas {{active?'activas':'inactivas'}}
                            </button>
                            <mat-menu #menuFilterActive="matMenu">
                                <button [disabled]="active" (click)="getCampaignsListPNP(1);" color="accent" mat-menu-item>
                                    <span>Activas</span>
                                </button>
                                <button [disabled]="!active" (click)="getCampaignsListPNP(0);" color="accent" mat-menu-item>
                                    <span>Inactivas</span>
                                </button>
                            </mat-menu>
                        </div>
                        <mat-form-field color="accent">
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. POP AGOSTO 2023" #input>
                        </mat-form-field>
                    </div>
                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="accent" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                <td mat-cell *matCellDef="let row"> <a [href]="'/admin/dashboardboxpnp/' + row.id"><u>{{row.name}}</u></a> </td>
                            </ng-container>

                            <ng-container matColumnDef="created_at">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Inicio </th>
                                <td mat-cell *matCellDef="let row"> {{row.created_at | date: 'dd/MM/yyyy'}} </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="total_boxes">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cajas Totales </th>
                                <td mat-cell *matCellDef="let row"> {{row.total_boxes}} </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="start_boxes">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Entraron a linea </th>
                                <td mat-cell *matCellDef="let row"> {{row.start_boxes}} </td>
                            </ng-container>

                            <ng-container matColumnDef="ended_boxes">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Cerradas </th>
                                <td mat-cell *matCellDef="let row"> {{row.ended_boxes}} </td>
                            </ng-container>

                            <ng-container matColumnDef="rest_boxes">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Restantes </th>
                                <td mat-cell *matCellDef="let row"> {{row.rest_boxes}} </td>
                            </ng-container>

                            <ng-container matColumnDef="materials">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> # Materiales </th>
                                <td mat-cell *matCellDef="let row"> <a [href]="'/admin/dashboardmatpnp/' + row.id"><u>{{row.materials}}</u></a> </td>
                            </ng-container>

                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td mat-cell *matCellDef="let row"> <span class="badge text-uppercase" [ngClass]="row.status == 'activa' ? 'badge-info' : 'badge-success'">{{row.status}}</span> </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center" (click)="$event.stopPropagation()">
                                    <button mat-icon-button matTooltip="Gráficas" [matTooltipPosition]="'above'" color="accent" (click)="openDialogCampaignsDashboard(row)">
                                        <mat-icon>bar_chart</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row class="cursor-pointer" *matRowDef="let row; columns: displayedColumns;" ></tr>                
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">
                                    <span *ngIf="campaignListPNP.length == 0;else notFound">No hay información para mostrar</span>
                                    <ng-template #notFound>
                                        No existe información que coincida con "{{input.value}}" 
                                    </ng-template>
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>