<div class="modal-header">
    <h5 class="fw-bold pt-4 ps-4">
        <mat-icon class="me-1 align-middle">store</mat-icon>{{ box.sucursal }}
    </h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>

<mat-dialog-content class="mat-typography">
    <div class="modal-body">
        <div class="overflow-auto w-100">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <h5 class="fw-bold">
                        <span class="text-uppercase badge bg-light"><mat-icon class="ms-3 align-middle txt-primary">inventory_2</mat-icon><strong class="ms-1 me-3 txt-dark h6 mt-1 pt-2 pb-2 ps-2 pe-2">{{box.box_id}}</strong></span>
                    </h5>
                </div>
                <div class="offset-lg-4 offset-md-4 col-lg-4 col-md-4">
                    <mat-form-field class="w-100">
                        <mat-label>
                            <mat-icon>search</mat-icon> Buscar Material</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ej. Unibanner" #input>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="mat-elevation-z8 table-responsive">
            <table mat-table [dataSource]="dataSource" matSort class="w-100">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                    <td mat-footer-cell *matFooterCellDef><strong>Materiales Totales</strong></td>
                </ng-container>

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header matSortStart="asc"> SKU </th>
                    <td mat-cell *matCellDef="let row"> {{row.sku}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </th>
                    <td mat-cell *matCellDef="let row"> {{row.cantidad}} </td>
                    <td mat-footer-cell *matFooterCellDef> <strong>{{ getTotal() }}</strong> </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">No se encontraron materiales para esta caja.</td>
                </tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>

            <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                <mat-progress-spinner color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </div>
    </div>
</mat-dialog-content>