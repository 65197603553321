import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DistributionsService } from 'src/app/services/distributions.service';
import SignaturePad from "signature_pad";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mf-doc',
  templateUrl: './mf-doc.component.html',
  styleUrls: ['./mf-doc.component.scss']
})
export class MfDocComponent implements AfterViewInit  {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('canvas')
  private canvas: ElementRef = {} as ElementRef;
  dataSource!: MatTableDataSource<any>;
  cols: string[] = ['guia', 'direccion'];
  mf!: any;
  id_campana!: number;
  sig!: SignaturePad;
  bodyForm : FormData = new FormData(); 

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private distService : DistributionsService,
    private dialogRef: MatDialogRef<MfDocComponent>,
  ) {
    this.mf = this.data.mf;
    this.id_campana = this.data.id_campana;
  }

  ngAfterViewInit(): void {
    this.sig = new SignaturePad(this.canvas.nativeElement);
  }

  ngOnInit(): void {
    this.preloadFormData()
  }

  preloadFormData(){
    this.bodyForm.append('id_mf', this.data.mf.id);
    this.bodyForm.append('id_campana', this.data.id_campana);
  }

 dataURItoBlob(dataURI: string) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
  else
      byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type:mimeString});
}

  loadFormSign() {
    const base64ImageData = this.sig.toDataURL();
    const fileImg = this.dataURItoBlob(base64ImageData);
    this.bodyForm.append('blob', fileImg as File);
  }

  clear() {
    this.sig.clear();
  }

  applyFilter(event: Event, ds: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    ds.filter = filterValue.trim().toLowerCase();
    if (ds.paginator) ds.paginator.firstPage();
  }

  saveMfSignature() {
      Swal.fire({
        icon: 'info',
        title: 'Guardando...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.loadFormSign();
      this.distService.saveSignatureMf(this.bodyForm).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.saveMfDoc();
          }
        },
        (err:any) => {
          console.log(err);
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
          });
        }
      );

  }

  saveMfDoc() {
    this.distService.saveDocMf(this.data.mf.id).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Manifiesto confirmado de manera correcta!'
          }).then(() => {
            window.open(result.manifest, "_blank");
            this.dialogRef.close({ reload: true });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
        });
      }
    );
  }
  
}
