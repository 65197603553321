import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserModel } from 'src/app/models/users.model';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() menuEvent = new EventEmitter<boolean>();

  userObj!: UserModel;
  roleName!: any;
  userCredit!: string;
  currency!: string;

  clickEventsubscription!: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,
    private userS: UsersService
  ) {
  }

  ngOnInit(): void {
    this.userObj = this.userS.getUser();
    this.roleName = environment.roles.find(el => el.id === this.userObj.id_rol)?.rol;
  }

  menuToggle() {
    this.menuEvent.emit(true);
  }

  logOut() {
    this.auth.logout();
    this.router.navigateByUrl('/login');
  }

}
