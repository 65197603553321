import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MaterialsModelPNP } from 'src/app/models/campaigns.model';
import { DistributionsService } from 'src/app/services/distributions.service';

@Component({
  selector: 'app-bg-materials-list',
  templateUrl: './bg-materials-list.component.html',
  styleUrls: ['./bg-materials-list.component.scss']
})
export class BgMaterialsListComponent {

  materialList = new Array<MaterialsModelPNP>();
  isLoading = true;
  bg!: any;

  displayedColumns: string[] = ['id', 'name', 'quantity'];
  dataSource!: MatTableDataSource<MaterialsModelPNP>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private distS: DistributionsService
  ) {
    this.bg = this.data.bg;
   }

  ngOnInit(): void {    
    this.getMaterialList(this.data.bg.id);
  }

  getMaterialList(bg_id: number) {
    this.distS.getBgMaterialsListPNP(bg_id).subscribe(
      (result : any) => {
        this.materialList = result;
        this.dataSource = new MatTableDataSource(this.materialList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );    
  }

  getTotal() {
    return this.materialList.map(m => m.cantidad).reduce((acc, value) => acc + value, 0);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
