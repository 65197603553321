import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MaterialsModelPNP } from 'src/app/models/campaigns.model';
import { DistributionsService } from 'src/app/services/distributions.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-close-boxes',
  templateUrl: './close-boxes.component.html',
  styleUrls: ['./close-boxes.component.scss']
})
export class CloseBoxesComponent {
  isLoading = false;
  formP!: FormGroup;
  showTable: boolean = false;
  displayedColumns: string[] = ['id', 'consecutivo', 'zona', 'sucursal', 'actions'];
  dataSource!: MatTableDataSource<any>;
  id_campana!: number;
  carriersList : any;
  boxList: any = [];
  boxSelected: any = [];
  carrierSelected!: string;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private fb: FormBuilder,
    private distS: DistributionsService,
    private dialogRef: MatDialogRef<CloseBoxesComponent>
  ) {
    this.id_campana = this.data.id_campana;
  }

  ngOnInit(): void { 
    this.getListCarriers();
    this.createFormP();
  }

  createFormP(){
    this.formP = this.fb.group({
      paqueteria: ['', [ Validators.required]]
    });
  }

  getListCarriers(){
    this.distS.getCarriersLine(this.id_campana).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.carriersList = result.data;
          if(this.carriersList.length > 0){
            const f_carrier = this.formP.get('paqueteria');
            if(f_carrier)  { 
              f_carrier.setValue(this.carriersList[0]);
            }
          }
        }
      },
      (err:any) => {
        console.log(err);
      }
    );
  }

  selBox(id: number) {
    this.boxSelected = [];
    if(id==0) {
      this.boxSelected = this.boxList;
    }
    else {
      this.boxSelected.push(id);
    }
    this.ruSure();
  }

  getBoxsList(event: any , carrier: string){
    this.boxList = [];
    this.isLoading = true;
    this.showTable = true;
    if(event.source.selected&&carrier) {
      this.carrierSelected = carrier;
      this.distS.getListBoxByCarrier(this.id_campana, carrier).subscribe(
        (result: any) => {
          this.boxList = result.data.map((e:any)=>e.id);
          this.dataSource = new MatTableDataSource(result.data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        },
        (err: any) => {
          console.log(err);
          this.isLoading = false;
        }
      );
    }
    this.isLoading = false;
  }

  ruSure() {
    if(this.boxList.length > 0) {
      Swal.fire({
        title: '¿Estás seguro que deseas cerrar las cajas seleccionadas?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {      
        if (result.isConfirmed) {
          this.setBoxsListClosed();
        }
      });
    }
  }

  setBoxsListClosed(){
    if(this.carrierSelected != "" &&  this.boxSelected.length > 0) {
      this.distS.setCloseListBox(this.id_campana, this.carrierSelected, this.boxSelected).subscribe(
        (result: any) => {
          if(result.message == "Success") {
            Swal.fire({
              icon: 'success',
              title: 'Guardado',
              text: 'Cajas cerradas de manera correcta!'
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
        },
        (err: any) => {
          console.log(err);
          this.isLoading = false;
        }
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
