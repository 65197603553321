<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3><mat-icon class="align-middle">campaign</mat-icon> Campañas</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon color="accent">campaign</mat-icon></a></li>
                    <li class="breadcrumb-item">Lista de Campañas</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <div class="float-end">
                            <button [matMenuTriggerFor]="menuCalidad" mat-raised-button color="primary" class="mt-2 mb-2 ms-4 text-white">
                                    Calidad de videos: ({{quality}}p) <mat-icon>settings</mat-icon>
                            </button>
                            <mat-menu #menuCalidad="matMenu">
                                <button [disabled]="q.quality==quality" *ngFor="let q of displayQuality" (click)="editQVideo(q.quality);" color="accent" mat-menu-item>
                                    <mat-icon>{{q.item}}</mat-icon>
                                    <span>{{q.quality}}p</span>
                                </button>
                            </mat-menu> 
                            <button mat-raised-button color="primary" (click)="openDialogCampaignsCreate()" class="mt-2 mb-2 ms-4 text-white">
                                    Generar campaña <mat-icon>add</mat-icon>
                            </button>
                        </div>
                        <mat-form-field color="accent">
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. mail@mail.com" #input>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="accent" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="compania">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Compañía </th>
                                <td mat-cell *matCellDef="let row"> {{row.compania}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="created_at">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creación </th>
                                <td mat-cell *matCellDef="let row"> {{row.created_at | date: 'dd/MM/yyyy'}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="file">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Archivo asignado</th>
                                <td  mat-cell *matCellDef="let row">
                                    <mat-icon *ngIf="row.file;else noFile" class="ms-5" color="primary">check_circle</mat-icon>
                                    <!-- <a *ngIf="row.file;else noFile" style="color: #0091ea;" target="_blank" [href]="'https://docs.google.com/spreadsheets/d/'+row.file"> {{row.file_name||'Sin archivo seleccionado'}} </a> -->
                                    <ng-template #noFile>
                                        <!-- Sin archivo seleccionado -->
                                        <mat-icon class="ms-5" color="warn">cancel</mat-icon>
                                    </ng-template>
                                </td>
                            </ng-container>
                
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center" (click)="$event.stopPropagation()">
                                    <mat-slide-toggle [checked]="row.active == 1" (click)="activateCampaign(row.id, row.active, row.name)" matTooltip="¿Activo?" [matTooltipPosition]="'above'" color="accent" class="pb-3 align-middle"></mat-slide-toggle>
                                    <button mat-icon-button [matTooltip]="row.cloud_video==1?'¿Desactivar videos en la nube?':'¿Activar videos en la nube?'" [matTooltipPosition]="'above'" [color]="row.cloud_video==1?'accent':''"  (click)="activateCloudVideo(row.id, row.cloud_video)">
                                        <mat-icon>cloud_upload</mat-icon>
                                    </button>
                                    <button mat-icon-button [matMenuTriggerFor]="menuCampana" matTooltip="Más acciones" [matTooltipPosition]="'above'" color="accent">
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menuCampana="matMenu">
                                        <button (click)="openDialogCampaignEdit(row)" color="accent" mat-menu-item>
                                            <mat-icon>edit</mat-icon>
                                            <span>Editar</span>
                                        </button>           
                                        <button (click)="deleteCampaign(row.id)" mat-menu-item>
                                            <mat-icon>delete</mat-icon>
                                            <span>Eliminar</span>
                                        </button>
                                    </mat-menu> 
                                </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row class="cursor-pointer" *matRowDef="let row; columns: displayedColumns;" (click)="goToCampaign(row.id)" ></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">
                                    <span *ngIf="campaignList.length == 0;else notFound">No hay información para mostrar</span>
                                    <ng-template #notFound>
                                        No existe información que coincida con "{{input.value}}" 
                                    </ng-template>
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>