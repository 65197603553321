<div class="modal-header">
    <h5 class="pt-4 ps-4">Editar campaña</h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <span>Edita la información de la campaña:</span>
    <form autocomplete="off" [formGroup]="formCampaign" (ngSubmit)="ruSure()" class="mt-3" id="campaignForm">
        <small>Campo obligatorio *</small>
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-12">
                <!-- <mat-form-field class="w-100" color="accent">
                    <mat-label>Archivo</mat-label>
                    <mat-select formControlName="file" name="file">
                        <mat-option *ngFor="let file of fileList" [value]="file.id" (click)="selectedFile(file)">
                            {{file.name}}.xlsx
                        </mat-option>
                    </mat-select>
                    <mat-icon matSuffix>description</mat-icon>
                </mat-form-field> -->
                <div *ngIf="campaign.file;else noFile">
                    <h6>Archivo asignado</h6>
                    <a style="color: #0091ea;" target="_blank" [href]="'https://docs.google.com/spreadsheets/d/'+campaign.file"> {{fileName(campaign.file)}} </a>       
                </div>
                <ng-template #noFile>
                    <button mat-flat-button (click)="openDialogSelectFile()" type="button" color="accent" class="text-white w-100">Seleccionar archivo<mat-icon>cloud_upload</mat-icon></button>
                </ng-template>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="campaign.file" mat-flat-button (click)="openDialogSelectFile()" type="button" color="accent" class="text-white">Editar archivo de guias<mat-icon>edit</mat-icon></button>
    <button mat-flat-button color="primary" class="text-white" form="campaignForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>
