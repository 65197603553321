<div class="modal-header">
    <h5 class="pt-4 ps-4">Edita usuario</h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <span>Edita la información del usuario:</span>
    <form autocomplete="off" [formGroup]="formUser" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
        <small>Todos los campos son obligatorios *</small>
        <div class="row mt-3">
            <div class="col-sm-12">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Nombre completo</mat-label>
                    <input matInput formControlName="name">
                    <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-sm-6">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Selecciona perfil</mat-label>
                    <mat-select formControlName="id_rol">
                        <mat-option *ngFor="let rol of rolesList" [value]="rol.id">{{rol.rol}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>            
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" class="me-3 mb-1" type="button" (click)="openDialogPassword()">
        Cambiar contraseña <mat-icon>password</mat-icon>
    </button>
    <button mat-flat-button color="primary" class="text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>
