<div class="modal-header">
    <h5 class="pt-4 ps-4">Campaña: <strong>{{ data.campaign.name }}</strong></h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <span>Sube un nuevo archivo de distribución:</span>
    <form [formGroup]="form">
        <ngx-file-drag-drop class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
        </ngx-file-drag-drop>
    </form>
    <div class="row mb-4" *ngIf="res_errors">
        
        <mat-accordion *ngFor="let e of res_errors">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{e.title}}
                </mat-panel-title>
                <mat-panel-description>
                  {{e.message}}
                </mat-panel-description>
                </mat-expansion-panel-header>
                <table mat-table [dataSource]="getDataSource(e.data)" class="mat-elevation-z8">
                    <ng-container *ngFor="let header of getHeaders(e.data)" [matColumnDef]="header">
                        <th mat-header-cell *matHeaderCellDef> {{header}} </th>
                        <td mat-cell *matCellDef="let element"> {{element[header]}} </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="getHeaders(e.data);"></tr>
                    <tr mat-row *matRowDef="let row; columns: getHeaders(e.data);"></tr>
                </table>
            </mat-expansion-panel>
          </mat-accordion>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="bg-success text-white" form="userForm" (click)="uploadFile()" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>