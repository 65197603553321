import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DistributionsService } from 'src/app/services/distributions.service';
import { MainService } from 'src/app/services/main.service';
import { FileValidators } from 'ngx-file-drag-drop';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-distribution-upload-file',
  templateUrl: './distribution-upload-file.component.html',
  styleUrls: ['./distribution-upload-file.component.scss']
})
export class DistributionUploadFileComponent implements OnInit {
  bodyForm : FormData = new FormData(); 
  form!: FormGroup;
  fileControl = new FormControl([],[FileValidators.uniqueFileNames, FileValidators.maxFileCount(1)]);
  campaign : any;
  isClientUsr: any;
  responsedata : any;
  res_errors: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private distService : DistributionsService,
    private main : MainService,
    private fb : FormBuilder,
    private dialogRef: MatDialogRef<DistributionUploadFileComponent>,
  ) { 
    this.campaign = this.data.campaign;
  }

  ngOnInit(): void {
    this. preloadFormData();
    this.loadForm();
  }

  preloadFormData(){
    this.bodyForm.append('id_campana', this.campaign.id);
    this.bodyForm.append('id_company', this.campaign.id_compania);
  }
  
  loadForm() {
    this.form = this.fb.group({
      blob : [null, {validators:[Validators.required]}]
    });
  }
  
  onValueChange(files : any)
  { 
    this.res_errors = null;
    if(files.length >= 2)
    {      
      files.splice(1, 1);
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'Sólo puede adjuntarse un documento'
      });
    }

    for (const item of files) {
      if(!(item.name.endsWith('.xls') || item.name.endsWith('.xlsx')))
      {
        const index = files.indexOf(item);
  
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          html: 'Solo se permiten documentos en formato .XLSX'
        });
    
        if (index >= 0) {
          files.splice(index, 1);
        }
      }
      else {
        this.form.value.blob = files;
      }
    }
  }
  
  uploadFile(){
    console.log(this.form.value);
    if(this.form.value.blob != null && this.form.value.blob?.length!=0){
        this.bodyForm.append("blob", this.form.value.blob[0] as File);
      
      Swal.fire({
        icon: 'info',
        title: 'Cargando archivo...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      this.distService.uploadDist(this.bodyForm).subscribe(
        (result:any)=>{
          if(result.message == 'Success'){
            Swal.fire({
              icon: 'success',
              title: 'Archivo cargado',
              text: 'Archivo cargado exitosamente'          
            }).then(() => {            
              this.dialogRef.close({ reload: true });
            });
          }
          else {
            this.res_errors = result.errors;
            Swal.fire({
              icon: 'warning',
              title: 'Datos invalidos',
              html: 'Se encontraron datos invalidos durante la carga del archivo'
            });
          }
        },
        (err:any)=>{
          this.main.HideLoading();
          if(err.error.message == "Invalid file") {
            Swal.fire({
              icon: 'warning',
              title: 'Error',
              html: 'Solo se permiten documentos en formato .XLSX'
            });
      
          }
          else {
            Swal.fire({
              icon: 'error',
              title: 'Error al guardar',
              text: 'Ocurrió un error al guardar el archivo, por favor vuelve a intentarlo.'
            });
          }
        }
      )
      
  }else{
      Swal.fire({
        icon: 'warning',
        title: 'Error',
        html: 'El documento no ha sido adjuntado'
      });
    }
  }

  getHeaders(res_data: any) {
    let headers: any[] = [];
    if(res_data) {
      res_data.map((e:any) => {
        if(typeof e === 'string') {
          if(!headers.find((header) => header == "Nombre")){
            headers.push("Nombre");
          }
        }
        else {
          Object.keys(e).map((key) => {
            if(!headers.find((header) => header == key)){
              headers.push(key)
            }
          })
        }
      })
    }
    return headers;
  }

  getDataSource(e:any) {
    let custom_ds = new MatTableDataSource<any>;
    if(typeof e[0] === 'string') {
      custom_ds = new MatTableDataSource(e.map((m:any) => Object.assign({}, {"Nombre": m})));
    }
    else {
      custom_ds = new MatTableDataSource(e);
    }
    return custom_ds;
  }

  getType(e:any){
    return typeof e;
  }
}

