<div class="modal-header">
    <h5 class="pt-4 ps-4"><mat-icon class="align-middle">gesture</mat-icon> Firma de manifiesto: <strong>{{data.mf.nombre}}</strong></h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <mat-list role="list">
        <div class="container">
            <div class="row">
                <div class="col">   
                    <mat-list-item role="listitem"><strong class="pe-2">Empresa:</strong>{{data.mf.empresa}}</mat-list-item>
                </div>
                <div class="col">   
                    <mat-list-item role="listitem"><strong class="pe-2">Paquetería:</strong>{{data.mf.paqueteria}}</mat-list-item>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-list-item role="listitem"><strong class="pe-2">Recolector:</strong>{{data.mf.recolector}}</mat-list-item>
                </div>
                <div class="col">
                    <mat-list-item role="listitem"><strong class="pe-2">Cantidad de paquetes:</strong>{{data.mf.cantidad}}</mat-list-item>
                </div>
            </div>
            <div class="row">
                <div class="container text-center">
                    <strong class="w-100 h5">{{data.mf.recolector}}</strong>
                    <canvas #canvas class="sign-dashed" width="400" height="300"></canvas>
                </div>
            </div>
        </div>
    </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" (click)="clear()">Limpiar </button>
    <button mat-flat-button class="bg-success text-white" type="submit" (click)="saveMfSignature()">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>