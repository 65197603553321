import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChartComponent, ApexNonAxisChartSeries, ApexResponsive, ApexChart } from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries | any;
  chart: ApexChart | any;
  responsive: ApexResponsive[] | any;
  labels: any;
};

@Component({
  selector: 'app-dashboard-pnp-charts',
  templateUrl: './dashboard-pnp-charts.component.html',
  styleUrls: ['./dashboard-pnp-charts.component.scss']
})
export class DashboardPnpChartsComponent implements OnInit {
  isLoading = true;
  pieChart: any;
  totalBoxes: number;
  campaing: string;
  status: string;
  
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
  ) {
    console.log(data);
    this.totalBoxes = data.total_boxes;
    this.campaing = data.name;
    this.status = data.status;
    this.chartOptions = {
      series: [data.rest_boxes, data.start_boxes],
      chart: {
        width: 400,
        type: "pie"
      },
      labels: ["Restantes: " + data.rest_boxes, "Escaneadas: " + data.start_boxes],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    };
  }

  ngOnInit(): void {
  }

}
