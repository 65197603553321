import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileModel } from 'src/app/models/campaigns.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { GoogleDriveService } from 'src/app/services/google-drive.service';
import Swal from 'sweetalert2';
import { CampaignsSelectFileComponent } from '../campaigns-select-file/campaigns-select-file.component';

@Component({
  selector: 'app-campaigns-edit',
  templateUrl: './campaigns-edit.component.html',
  styleUrls: ['./campaigns-edit.component.scss']
})
export class CampaignsEditComponent implements OnInit {

  formCampaign!: FormGroup;
  id_campaign!: number;
  isLoading = true;
  fileList = new Array<FileModel>();
  selectedValue!: string;
  fileSelected!: string;
  campaign?:any;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private campaignS: CampaignsService,
    private driveS: GoogleDriveService,
    private dialogRef: MatDialogRef<CampaignsEditComponent>,
    private matDialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.id_campaign = this.data.campaignSel.id;
    this.campaign = this.data.campaignSel;
    this.createFormCampaign();
    this.setCampaignForm();
    this.getFiles();
    
  }

  selectedFile( file: FileModel ){
    this.fileSelected = file.name
  }

  fileName(id: string) {
    const foundFile = this.fileList.find(el => el.id === id);
    return foundFile?.name;
  }

  createFormCampaign() {
    this.formCampaign = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3)]],
      file: ['', [ Validators.minLength(3)]],
      cloud_video:[]
    });
  }

  setCampaignForm() {
    const { id, created_at, active, file_name,id_compania,file_dist,file_bg, file_guias, file_racks, materials, ...rest } = this.data.campaignSel;
    this.formCampaign.setValue(rest);
  }

  ruSure() {
    if(this.formCampaign.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas editar la campaña?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveCampaign();
      }
    });
  }

  saveCampaign() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.formCampaign.value.file_name = this.fileSelected;
    this.campaignS.editCampaign(this.formCampaign.value, this.id_campaign).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Usuario guardado de manera correcta!'
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {        
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  getFiles() {
    this.driveS.getFiles().subscribe(
      (result : any) => {
        console.log(result)
        this.fileList = result;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  openDialogSelectFile() {
    this.matDialog.open(CampaignsSelectFileComponent, 
      {
        panelClass: 'dialogs-main',
        data:{
          campaign: this.data.campaignSel,
        }      
      })
      .afterClosed().subscribe(
      resp => {   
        this.dialogRef.close({ reload: false });     
          // this.isLoading = true;
      }
    );
  }
}
