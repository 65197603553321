import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BoxModelPNP, CampaignModel } from 'src/app/models/campaigns.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { DistributionsService } from 'src/app/services/distributions.service';

@Component({
  selector: 'app-dashboard-pnp-box',
  templateUrl: './dashboard-pnp-box.component.html',
  styleUrls: ['./dashboard-pnp-box.component.scss']
})
export class DashboardPnpBoxComponent {
  boxListPNP = new Array<BoxModelPNP>();
  campaignList = new Array<CampaignModel>();
  isLoading = false;
  selectedValue!: number;

  displayedColumns: string[] = ['id', 'consecutivo', 'zona', 'sucursal', 'entro_linea', 'cerrada', 'actions'];
  dataSource!: MatTableDataSource<BoxModelPNP>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private campaignS: CampaignsService,
    private distS: DistributionsService,
    private matDialog: MatDialog,
    private _params: ActivatedRoute,
  ) {
    this._params.params.subscribe(params => {
      if(params["id"]&&_isNumberValue(params["id"])){
        this.selectedValue = parseInt(params["id"]);
        this.getBoxsListPNP();
      }
    });
   }

  ngOnInit(): void {
    this.getCampaignList();
  }

  onSelected(id_camp: number) {
    this.selectedValue = id_camp;
    this.getBoxsListPNP();
  }

  getCampaignList() {
    this.campaignS.getCampaingList().subscribe(
      (result: any) => {
        this.campaignList = result;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getBoxsListPNP(){
    this.isLoading = true;
    this.distS.getCampBoxListPNP(this.selectedValue).subscribe(
      (result: any) => {
        this.boxListPNP = result;
        this.dataSource = new MatTableDataSource(this.boxListPNP);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
