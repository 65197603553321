import { _isNumberValue } from '@angular/cdk/coercion';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CampaignModel, CampaignModelPNP, MaterialsModelPNP } from 'src/app/models/campaigns.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { DistributionsService } from 'src/app/services/distributions.service';

@Component({
  selector: 'app-dashboard-pnp-mat',
  templateUrl: './dashboard-pnp-mat.component.html',
  styleUrls: ['./dashboard-pnp-mat.component.scss']
})
export class DashboardPnpMatComponent {
  
  materialListPNP = new Array<MaterialsModelPNP>();
  campaignList = new Array<CampaignModel>();
  isLoading = false;
  selectedValue!: number;

  displayedColumns: string[] = ['nombre', 'sku', 'cantidad', 'packed', 'omitted', 'missed'];
  dataSource!: MatTableDataSource<MaterialsModelPNP>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private campaignS: CampaignsService,
    private distS: DistributionsService,
    private matDialog: MatDialog,
    private _params: ActivatedRoute,
  ) {
    this._params.params.subscribe(params => {
      if(params["id"]&&_isNumberValue(params["id"])){
        this.selectedValue = parseInt(params["id"]);
        this.getCampaignsListPNP();
      }
    });
   }

  ngOnInit(): void {
    this.getCampaignList();
  }

  getCampaignList() {
    this.campaignS.getCampaingList().subscribe(
      (result: any) => {
        this.campaignList = result;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  onSelected(id_camp: number) {
    this.selectedValue = id_camp;
    this.getCampaignsListPNP();
  }

  getCampaignsListPNP(){
    this.isLoading = true;
    this.distS.getCampMaterialsListPNP(this.selectedValue).subscribe(
      (result: any) => {
        this.materialListPNP = result;
        this.dataSource = new MatTableDataSource(this.materialListPNP);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
    this.isLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
