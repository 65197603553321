<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h3 class="fs-6 fs-sm-1">
            <mat-icon class="align-middle mb-1">barcode_reader</mat-icon> Dashboard Distribución P&P
          </h3>
        </div>
        <div class="col-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="index.html"><mat-icon color="accent">barcode_reader</mat-icon></a>
            </li>
            <li class="breadcrumb-item">Dashboard Distribución P&P</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
      <div class="row">
          <div class="col-sm-12">
              <div class="card">
                  <div class="card-body">
                    <div class="row">
                        <div class="col-lg-4 col-md-4">
                            <mat-form-field class="w-100" appearance="outline">
                                <mat-label>Campaña</mat-label>
                                <mat-select [(value)]="selectedValue">
                                    <mat-option *ngFor="let campaign of campaignList" [value]="campaign.id" (onSelectionChange)="onSelected(campaign.id);" >
                                        {{ campaign.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="offset-lg-4 offset-md-4 col-lg-4 col-md-4">
                            <mat-form-field class="w-100" color="accent">
                                <mat-label>
                                    <mat-icon>search</mat-icon> Buscar</mat-label>
                                  <input matInput (keyup)="applyFilter($event)" placeholder="Ej. POP AGOSTO 2023" #input>
                            </mat-form-field>
                        </div>
                    </div>
                      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                          <mat-progress-bar color="accent" mode="indeterminate">
                          </mat-progress-bar>
                      </div>
                      <div class="mat-elevation-z8 table-responsive">
                          <table mat-table [dataSource]="dataSource" matSort class="w-100">
                  
                              <ng-container matColumnDef="id">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                  <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                              </ng-container>
                  
                              <ng-container matColumnDef="consecutivo">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Consecutivo </th>
                                  <td mat-cell *matCellDef="let row"> <strong>{{row.consecutivo}}</strong> </td>
                              </ng-container>
  
                              <ng-container matColumnDef="zona">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Zona </th>
                                  <td mat-cell *matCellDef="let row"> {{row.zona}} </td>
                              </ng-container>
                              
                              <ng-container matColumnDef="sucursal">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Sucursal </th>
                                  <td mat-cell *matCellDef="let row"> {{row.sucursal}} </td>
                              </ng-container>
                              
                              <ng-container matColumnDef="entro_linea">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Entró a linea </th>
                                  <td mat-cell *matCellDef="let row"> {{row.entro_linea}} </td>
                              </ng-container>
                              
                              <ng-container matColumnDef="cerrada">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Caja finalizada </th>
                                <td mat-cell *matCellDef="let row"> {{row.cerrada}} </td>
                                </ng-container>
  
                              <ng-container matColumnDef="video">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Video </th>
                                  <td mat-cell *matCellDef="let row"> <a *ngIf="row.video.length>0" [href]="row.video" target="_blank">Ver video</a></td>
                              </ng-container>
  
                              <ng-container matColumnDef="actions">
                                  <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                  <td mat-cell *matCellDef="let row" class="text-center" (click)="$event.stopPropagation()">
                                      <a mat-icon-button matTooltip="Video" [matTooltipPosition]="'above'" color="accent" *ngIf="row.video.length>0" [href]="row.video" target="_blank">
                                          <mat-icon>smart_display</mat-icon>
                                      </a>
                                  </td>
                              </ng-container>
                  
                              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                              <tr mat-row class="cursor-pointer" *matRowDef="let row; columns: displayedColumns;" ></tr>                
                              <tr class="mat-row" *matNoDataRow>
                                  <td class="mat-cell" colspan="6">
                                      <span *ngIf="boxListPNP.length == 0;else notFound">No hay información para mostrar</span>
                                      <ng-template #notFound>
                                          No existe información que coincida con "{{input.value}}" 
                                      </ng-template>
                                  </td>
                              </tr>
                          </table>
                          <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>