<div class="modal-header">
    <h5 class="pt-4 ps-4">Cambiar password</h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <div class="alert alert-warning" *ngIf="isUserLogged">
        Para ver reflejados los cambios cierra la sesión y vuelve a ingresar al sistema.
    </div>
    <span>Ingresa la nueva contraseña</span>
    <form autocomplete="off" [formGroup]="formPassword" (ngSubmit)="changePassword()" class="px-3 mt-3" id="passwordForm">
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Contraseña</mat-label>
                    <mat-icon matSuffix (click)="hidePass = !hidePass" role="button">{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="pass" maxlength="30">
                </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Confirma contraseña</mat-label>
                    <mat-icon matSuffix (click)="hidePassConfirm = !hidePassConfirm" role="button">{{hidePassConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hidePassConfirm ? 'password' : 'text'" formControlName="passConfirm" maxlength="30">
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar<mat-icon>close</mat-icon></button>
    <button mat-flat-button class="text-white" color="primary" form="passwordForm" type="submit" mat-dialog-close [disabled]="!formPassword.valid">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>
