import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DistributionsService } from 'src/app/services/distributions.service';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss']
})
export class MaterialsComponent {
  materialsList!: Array<any>;
  isLoading = true;

  displayedColumns: string[] = ['id', 'nombre', 'sku', 'tipo'];
  dataSource!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private distS: DistributionsService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.geMaterialsList();
  }

  geMaterialsList() {
    this.distS.getMaterials().subscribe(
      (result : any) => {
        if(result.data) {
          this.materialsList = result.data;
          this.dataSource = new MatTableDataSource(this.materialsList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.isLoading = false;
        }
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }
  
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
