import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MaterialsModelPNP } from 'src/app/models/campaigns.model';
import { DistributionsService } from 'src/app/services/distributions.service';

@Component({
  selector: 'app-box-materials-list',
  templateUrl: './box-materials-list.component.html',
  styleUrls: ['./box-materials-list.component.scss']
})
export class BoxMaterialsListComponent {
  materialList = new Array<MaterialsModelPNP>();
  isLoading = true;
  box!: any;

  displayedColumns: string[] = ['id', 'name', 'quantity'];
  dataSource!: MatTableDataSource<MaterialsModelPNP>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    private distS: DistributionsService
  ) {
    this.box = this.data.box;
   }

  ngOnInit(): void {    
    this.getMaterialList(this.data.box.box_id);
  }

  getMaterialList(box_id: number) {
    this.distS.getBoxMaterialsListPNP(box_id).subscribe(
      (result : any) => {
        this.materialList = result;
        this.dataSource = new MatTableDataSource(this.materialList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );    
  }

  getTotal() {
    return this.materialList.map(m => m.cantidad).reduce((acc, value) => acc + value, 0);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
