<div class="modal-header">
    <h5 class="pt-4 ps-4">Campaña: <strong>{{ data.campaign.name }}</strong></h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <span>Sube un nuevo archivo con los datos de los envios:</span>
    <form [formGroup]="form">
        <ngx-file-drag-drop [disabled]="validated" class="ngx-file_f" emptyPlaceholder="Arrastre o seleccione archivo" formControlName="blob" [formControl]="fileControl" (valueChanged)="onValueChange($event)">
        </ngx-file-drag-drop>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="bg-success text-white" form="userForm" (click)="uploadFile()" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>