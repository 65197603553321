import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileModel } from 'src/app/models/campaigns.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { GoogleDriveService } from 'src/app/services/google-drive.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-campaigns-select-file',
  templateUrl: './campaigns-select-file.component.html',
  styleUrls: ['./campaigns-select-file.component.scss']
})
export class CampaignsSelectFileComponent implements OnInit {

  fileList = new Array<FileModel>();
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private driveS: GoogleDriveService,
    private campaignS: CampaignsService,
    private dialogRef: MatDialogRef<CampaignsSelectFileComponent>,

  ) {}

  ngOnInit(): void {
    this.getFiles();
  }

  getFiles() {
    this.driveS.getFiles().subscribe(
      (result : any) => {
        this.fileList = result;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  selectFile(file:FileModel) {
    this.ruSure(file)
  }

  
  ruSure(file:FileModel) {
    Swal.fire({
      title: `¿Estás seguro de asignar el archivo ${file.name} a la campaña ${this.data.campaign.name}?`,
      text:'Una vez que completes esta acción, no podrás realizar modificaciones en el archivo de guías de la campaña. ¿Deseas continuar?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.assignFile(file);
      }
    });
  }

  assignFile(file:FileModel) {
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.campaignS.assignFile(file, this.data.campaign).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Archivo asignado de manera correcta!'
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        if(err?.error?.message?.includes('ER_DUP_ENTRY')) {
          Swal.fire({
            icon: 'warning',
            title: 'Error al guardar',
            text: 'Este archivo ya pertenece a otra campaña.'
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error al guardar',
            text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
          });
        }
        console.log(err);
      }
    );
  }

}
