<div class="modal-header">
    <h5 class="pt-4 ps-4">Nueva campaña</h5>
    <button type="button" class="btn-close me-4" mat-dialog-close></button>
</div>
<mat-dialog-content class="mat-typography">
    <span>Captura el nombre de la campaña:</span>
    <form autocomplete="off" [formGroup]="formCampaign" (ngSubmit)="ruSure()" class="mt-3" id="userForm">
        <small>Campo obligatorio *</small>
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Compañía </mat-label>
                    <mat-select formControlName="id_company">
                        <mat-option *ngFor="let p of companiesList" [value]="p.id" (onSelectionChange)="getListCampaigns(p.id);">{{p.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Nombre </mat-label>
                    <mat-select formControlName="id_nest">
                        <mat-option *ngFor="let p of campaignsList" [value]="p.id" (onSelectionChange)="onSelectName(p.nombre);">{{p.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>                
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="bg-success text-white" form="userForm" type="submit">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>