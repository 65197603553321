import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import  KeenSlider, { KeenSliderInstance } from 'keen-slider'

@Component({
  selector: 'app-distribution-materials-img-sliders',
  templateUrl: './distribution-materials-img-sliders.component.html',
  styleUrls: ['./distribution-materials-img-sliders.component.scss']
})
export class DistributionMaterialsImgSlidersComponent {
  @Input() slides!: {text: string, color: string}[]
  @ViewChild('sliderRef') sliderRef!: ElementRef<HTMLElement>;
  images : any;
  material_info : any;
  campana : any;
  validated: boolean = false;
  slider!: KeenSliderInstance;

  ngOnDestroy() {
    if (this.slider) this.slider.destroy()
  }

  ngAfterViewInit(){
    this.slider = new KeenSlider(this.sliderRef.nativeElement)
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
  ) { 
    this.images = this.data.material.imgs;
  }

  imageObject = [
    {
        image: "",
        nombre : ""
    }];

  ngOnInit(): void {
    this.generateImagesObjs();
  }

  generateImagesObjs(){
    this.imageObject = [];
    this.images.forEach((x:any)=>{
      this.imageObject.push({ "image" : x , "nombre" : this.data.material.nombre});
    });
  }

}
