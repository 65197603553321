<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3><mat-icon class="align-middle">category</mat-icon> Materiales</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon color="accent">category</mat-icon></a></li>
                    <li class="breadcrumb-item">Lista de Materiales</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <mat-form-field color="accent">
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. mail@mail.com" #input>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="accent" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                            </ng-container>

                            <ng-container matColumnDef="nombre">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                                <td mat-cell *matCellDef="let row"> {{row.nombre}} </td>
                            </ng-container>

                            
                            <ng-container matColumnDef="sku">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> SKU </th>
                                <td mat-cell *matCellDef="let row"> {{row.sku}} </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="tipo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                                <td mat-cell *matCellDef="let row"> {{row.tipo=='g'?'Grande':'Chico'}} </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">
                                    <span *ngIf="materialsList.length == 0;else notFound">No hay información para mostrar</span>
                                    <ng-template #notFound>
                                        No existe información que coincida con "{{input.value}}" 
                                    </ng-template>
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>