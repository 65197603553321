import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignModelPNP } from 'src/app/models/campaigns.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { DashboardPnpChartsComponent } from '../shared/dialogs/dashboard-pnp-charts/dashboard-pnp-charts.component';

@Component({
  selector: 'app-dashboard-pnp',
  templateUrl: './dashboard-pnp.component.html',
  styleUrls: ['./dashboard-pnp.component.scss']
})
export class DashboardPnpComponent implements OnInit {

  campaignListPNP = new Array<CampaignModelPNP>();
  isLoading = true;
  active: boolean = true;

  displayedColumns: string[] = ['id', 'name', 'created_at', 'total_boxes', 'start_boxes', 'ended_boxes', 'rest_boxes', 'materials', 'status', 'actions'];
  dataSource!: MatTableDataSource<CampaignModelPNP>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private campaignS: CampaignsService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getCampaignsListPNP(1);
  }

  getCampaignsListPNP(act: number){
    this.isLoading = true;
    this.active = act==1;
    this.campaignS.getCampaignListPNP(act).subscribe(
      (result: any) => {
        this.campaignListPNP = result;
        this.dataSource = new MatTableDataSource(this.campaignListPNP);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogCampaignsDashboard(row:CampaignModelPNP) {
    this.matDialog.open(DashboardPnpChartsComponent, {panelClass: 'dialogs-main', data: row}).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          const act = this.active?1:0;
          this.getCampaignsListPNP(act);
      }
    );
  }

}
