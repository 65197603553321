import {
  CampaignTrackingModel,
  PendingBoxes,
} from 'src/app/models/campaigns.model';

export const pieChart  = (percent:string) => {
  return (
    {
  colors:['#54BA4A'],
  series: [percent],
  chart: {
  type: 'radialBar',
  offsetY: -20,
  sparkline: {
    enabled: true
  }
},
plotOptions: {
  radialBar: {
    startAngle: -90,
    endAngle: 90,
    track: {
      background: "#e7e7e7",
      strokeWidth: '97%',
      margin: 5, // margin is in pixels
      dropShadow: {
        enabled: true,
        top: 2,
        left: 0,
        color: '#999',
        opacity: 1,
        blur: 2
      }
    },
    dataLabels: {
      name: {
        show: false
      },
      value: {
        offsetY: -2,
        fontSize: '22px'
      }
    }
  }
},
grid: {
  padding: {
    top: -10
  }
},
fill: {
  type: 'gradient',
  gradient: {
    shade: 'light',
    shadeIntensity: 0.4,
    inverseColors: false,
    opacityFrom: 1,
    opacityTo: 1,
    stops: [0, 50, 53, 91]
  },
},
labels: ['Average Results'],
})};

export let mapData: any = {
  zoom: 5,
  center: { lat: 22.856622, lng: -101.843085 },
  clickableIcons: false,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
};

export const shipmentTracking = ( campaignData: Array<CampaignTrackingModel> ) => {
  const formatearFecha = (date: string) => {
    const fechaEntrega = date.replace(/undefined-/g, '');
    const fecha = fechaEntrega.split(' ');
    const sinHora = fecha[0];
    const fechaObj = new Date(sinHora);
    const anio = fechaObj.getFullYear();
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const dia = fechaObj.getUTCDate().toString().padStart(2, '0');

    return `${mes}-${dia}`;
  };

  const cantidadEnvios = campaignData.length;

  campaignData.sort((a: CampaignTrackingModel, b: CampaignTrackingModel) => {
    const fechaA = new Date(a.fecha_entrega);
    const fechaB = new Date(b.fecha_entrega);
    return fechaA.getTime() - fechaB.getTime();
  });

  const enviosPorDia: number[] = [];
  const fechas: string[] = [];
  let enviosAnteriores = 0;

  campaignData.forEach((envio: CampaignTrackingModel) => {
    if (envio.estatus === 'successful') {
      const fechaEntrega = formatearFecha(envio.fecha_entrega);
      const index = fechas.indexOf(fechaEntrega);

      if (index === -1) {
        fechas.push(fechaEntrega);
        enviosPorDia.push(enviosAnteriores + 1);
        enviosAnteriores = enviosAnteriores + 1;
      } else {
        enviosPorDia[index] = enviosAnteriores + 1;
        enviosAnteriores = enviosPorDia[index];
      }
    }
  });

  const enviosRestantes: number[] = [];

  enviosPorDia.forEach((enviosRealizados: number) => {
    const enviosRestantesDiaActual = cantidadEnvios - enviosRealizados;
    enviosRestantes.push(enviosRestantesDiaActual);
  });

  return {
    series: [
      {
        name: 'Entregados',
        type: 'area',
        data: enviosPorDia,
      },
      {
        name: 'Pendientes',
        type: 'line',
        data: enviosRestantes,
      },
    ],
    chart: {
      toolbar: {
        show: false,
      },
      height: 300,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    fill: {
      type: ['gradient', 'solid'],
      opacity: [0.35, 1],
    },
    labels: fechas,
    markers: {
      size: 0,
    },
    yaxis: [
      {
        title: {
          text: 'Cantidad',
        },
      },
    ],
    xaxis: {
      labels: {
        trim: false,
      },
    },
    grid: {
      borderColor: 'rgba(196,196,196, 0.3)',
      padding: {
        top: -20,
        right: -55,
        bottom: 0,
      },
    },
    colors: ['#54BA4A', '#FFAA28'],
    tooltip: {
      shared: true,
      intersect: false,
    },
  };
};


// export const shipmentTracking2 = ( campaignData: Array<CampaignTrackingModel> ) => {
//   const formatearFecha = (date: string) => {
//     const fechaEntrega = date.replace(/undefined-/g, '');
//     const fecha = fechaEntrega.split(' ');
//     const sinHora = fecha[0];
//     const fechaObj = new Date(sinHora);
//     const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
//     const dia = fechaObj.getUTCDate().toString().padStart(2, '0');

//     return `${mes}-${dia}`;
//   };

//   const cantidadEnvios = campaignData.length;
//   let restantes = campaignData.length;

//   campaignData.sort((a: CampaignTrackingModel, b: CampaignTrackingModel) => {
//     const fechaA = new Date(a.fecha_entrega);
//     const fechaB = new Date(b.fecha_entrega);
//     return fechaA.getTime() - fechaB.getTime();
//   });

//   const enviosPorDia: number[] = [];
//   const fechas: string[] = [];
//   let enviosAnteriores = 0;



//   return {
//     series: [
//       {
//         name: 'Entregados',
//         type: 'area',
//         data: enviosPorDia,
//       },
//       {
//         name: 'Pendientes',
//         type: 'line',
//         data: restantes,
//       },
//     ],
//     chart: {
//       toolbar: {
//         show: false,
//       },
//       height: 300,
//       type: 'area',
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       width: 3,
//       curve: 'smooth',
//     },
//     fill: {
//       type: ['gradient', 'solid'],
//       opacity: [0.35, 1],
//     },
//     labels: fechas,
//     markers: {
//       size: 0,
//     },
//     yaxis: [
//       {
//         title: {
//           text: 'Cantidad',
//         },
//       },
//     ],
//     xaxis: {
//       labels: {
//         trim: false,
//       },
//     },
//     grid: {
//       borderColor: 'rgba(196,196,196, 0.3)',
//       padding: {
//         top: -20,
//         right: -55,
//         bottom: 0,
//       },
//     },
//     colors: ['#54BA4A', '#FFAA28'],
//     tooltip: {
//       shared: true,
//       intersect: false,
//     },
//   };
// };

export const ShipmentsPerDay = (campaignData: Array<CampaignTrackingModel>) => {
  const formatearFecha = (date: string) => {
    const fechaEntrega = date.replace(/undefined-/g, '');
    const fecha = fechaEntrega.split(' ');
    const sinHora = fecha[0];
    const fechaObj = new Date(sinHora);
    const anio = fechaObj.getFullYear();
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const dia = fechaObj.getUTCDate().toString().padStart(2, '0');

    return `${mes}-${dia}`;
  };
  campaignData.sort((a: CampaignTrackingModel, b: CampaignTrackingModel) => {
    const fechaA = new Date(a.fecha_entrega);
    const fechaB = new Date(b.fecha_entrega);
    return fechaA.getTime() - fechaB.getTime();
  });

  const enviosPorDia: number[] = [];
  const fechas: string[] = [];

  campaignData.forEach((envio: CampaignTrackingModel) => {
    if (envio.estatus === 'successful') {
      const fechaEntrega = formatearFecha(envio.fecha_entrega);
      const index = fechas.indexOf(fechaEntrega);

      if (index === -1) {
        fechas.push(fechaEntrega);
        enviosPorDia.push(1);
      } else {
        enviosPorDia[index]++;
      }
    }
  });

  return {
    chart: {
      toolbar: {
        show: false,
      },
      height:300,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: 'smooth',
    },
    yaxis: [
      {
        title: {
          text: 'Cantidad',
        },
      },
    ],
    xaxis: {
      categories: fechas,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      borderColor: 'rgba(196,196,196, 0.3)',
      padding: {
        top: -20,
        right: -55,
        bottom: 0,
      },
    },
    fill: {
      opacity: 0.2,
    },
    colors: ['#54BA4A'],
    series: [
      {
        name: 'Entregados',
        data: enviosPorDia,
      },
    ],
  };
};
