import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CampaignsService } from 'src/app/services/campaigns.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-campaigns-add',
  templateUrl: './campaigns-add.component.html',
  styleUrls: ['./campaigns-add.component.scss']
})
export class CampaignsAddComponent implements OnInit{

  formCampaign!: FormGroup;
  companiesList : any;
  campaignsList : any;
  campaignName!: string;

  constructor(
    private fb: FormBuilder,
    private campaignS: CampaignsService,
    private dialogRef: MatDialogRef<CampaignsAddComponent>
  ) {  }

  ngOnInit(): void {
    this.createFormCampaign();
    this.getListCompanies();

  }

  createFormCampaign(){
    this.formCampaign = this.fb.group({
      name: [''],
      id_company: ['', [ Validators.required]],
      id_nest: ['', [ Validators.required]],
    });
  }

  ruSure() {
    if(this.formCampaign.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas guardar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveCampaign();
      }
    });
  }

  saveCampaign() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.formCampaign.value.name = this.campaignName;
    this.campaignS.saveCampaign(this.formCampaign.value).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Campaña generada de manera correcta!'          
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelve a intentarlo.'
        });
      }
    );
  }

  getListCompanies(){
    this.campaignS.getCompanies().subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.companiesList = result.data;
          if(this.companiesList.length == 1){
            const f_company = this.formCampaign.get('company');
            if(f_company) f_company.setValue(this.companiesList[0].id);
          }
        }
      },
      (err:any) => {
        console.log(err);
      }
    );
  }

  getListCampaigns(id: number){
    this.campaignS.getNestCampaigns(id).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          this.campaignsList = result.data;
        }
        this.campaignName = "";
      },
      (err:any) => {
        console.log(err);
      }
    );
  }

  onSelectName(name: string){
    this.campaignName = name;
  }

}
