<div class="container-fluid">
    <div class="page-title">
        <div class="row">
            <div class="col-6">
                <h3><mat-icon class="align-middle">group</mat-icon> Usuarios</h3>
            </div>
            <div class="col-6">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="index.html"><mat-icon color="accent">group</mat-icon></a></li>
                    <li class="breadcrumb-item">Lista de Usuarios</li>
                </ol>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="overflow-auto w-100">
                        <div class="float-end">
                            <button mat-raised-button color="primary" (click)="openDialogUsersCreate()" class="mt-2 mb-2 text-white">
                                Agregar nuevo <mat-icon>person_add</mat-icon>
                            </button>
                        </div>
                        <mat-form-field color="accent">
                            <mat-label>
                                <mat-icon>search</mat-icon> Buscar</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Ej. mail@mail.com" #input>
                        </mat-form-field>
                    </div>

                    <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center; background: white;">
                        <mat-progress-bar color="accent" mode="indeterminate">
                        </mat-progress-bar>
                    </div>
                    <div class="mat-elevation-z8 table-responsive">
                        <table mat-table [dataSource]="dataSource" matSort class="w-100">
                
                            <ng-container matColumnDef="id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="role">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Rol </th>
                                <td mat-cell *matCellDef="let row"> {{row.rol_name}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                                <td mat-cell *matCellDef="let row"> {{row.email}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Nombre Completo</th>
                                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
                            </ng-container>
                
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef class="text-center"> Acciones </th>
                                <td mat-cell *matCellDef="let row" class="text-center">
                                    <button [disabled]="row.rol_name == 'admin'" mat-icon-button matTooltip="Editar" [matTooltipPosition]="'above'" (click)="openDialogUsersEdit(row)" color="accent">
                                        <mat-icon [hidden]="row.rol_name == 'admin'" >edit</mat-icon>
                                    </button>
                                    <mat-slide-toggle [checked]="row.active == 1" (click)="activateUser(row.id, row.active, row.name, row.id_serv)" matTooltip="¿Activo?" [matTooltipPosition]="'above'" color="accent" class="pb-3 align-middle"></mat-slide-toggle>
                                    <button mat-icon-button matTooltip="Eliminar" [hidden]="!isAdmin()" [matTooltipPosition]="'above'" color="warn" (click)="deleteUser(row.id)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">
                                    <span *ngIf="userList.length == 0;else notFound">No hay información para mostrar</span>
                                    <ng-template #notFound>
                                        <span>No existe información que coincida con "{{input.value}}"</span>
                                    </ng-template>
                                </td>
                            </tr>
                        </table>
                        <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>