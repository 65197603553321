import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { UsersEditPasswordComponent } from '../users-edit-password/users-edit-password.component';

@Component({
  selector: 'app-users-edit',
  templateUrl: './users-edit.component.html',
  styleUrls: ['./users-edit.component.scss']
})
export class UsersEditComponent {

  rolesList!: {
    id: number,
    rol: string
  }[];

  formUser!: FormGroup;
  id_user!: number;
  isUserLogged!: boolean;
  permissionList : any = [];
  selected : any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private fb: FormBuilder,
    private userS: UsersService,
    private dialogRef: MatDialogRef<UsersEditComponent>,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.rolesList = environment.roles;
    this.id_user = this.data.userSel.id;
    this.isUserLogged = this.id_user == this.userS.getUser().id;
    this.createFormUser();
    this.setUserForm();
  }

  createFormUser() {
    this.formUser = this.fb.group({
      name: ['', [ Validators.required, Validators.minLength(3)]],
      id_rol: ['', [Validators.required]],
    });
  }

  unselect(obj : any): void {
    obj = undefined;
 }

  getPermissions(permissions : any){
    if(this.formUser.value.id_rol > 1) {
      this.userS.getPermissionList(this.formUser.value.id_rol).subscribe(
        (result:any) => {
          if(result.message == "Success") {
            this.permissionList = result.data;
            this.selected = permissions.map((elem: { id: any; }) => elem.id);
          }
        },
        (err:any) => {
          console.log(err);
        }
      )
    }
    else this.permissionList = [];
  }

  setUserForm() {
    const { active, email, id, rol_name, ch_pass, ...userFormInfo } = this.data.userSel;
    this.formUser.setValue(userFormInfo);
  }

  ruSure() {
    if(this.formUser.invalid) { return; }
    Swal.fire({
      title: '¿Estás seguro que deseas editar el registro?',
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `Confirmar`,
      cancelButtonText: `Cancelar`
    }).then((result) => {      
      if (result.isConfirmed) {
        this.saveUser();
      }
    });
  }

  saveUser() {
    Swal.fire({
      icon: 'info',
      title: 'Guardando...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.formUser.value.comision = {tipo: this.formUser.value.tipo_comision, cantidad: this.formUser.value.cant_comision};
    const company = this.formUser.value.id_company;
    this.formUser.value.id_company = company ? company : 0;
    this.userS.editUser(this.formUser.value, this.id_user).subscribe(
      (result:any) => {
        if(result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            html: 'Usuario guardado de manera correcta!'
          }).then(() => {            
            this.dialogRef.close({ reload: false });
          });
        }
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al guardar',
          text: 'Ocurrió un error al guardar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  openDialogPassword() {
    this.matDialog.open(UsersEditPasswordComponent, {
      data: { 
        id_user: this.id_user 
      },
      panelClass: 'dialogs-sm',
    });
  }

}
