<div class="modal-header">
    <h5 class="pt-4 ps-4">Cambiar password</h5>
</div>
<mat-dialog-content class="mat-typography">
    <div class="alert alert-primary">
        Hola 👋 te damos la bienvenida a Buho Claw, para empezar a utilizar el sistema ingresa tu nueva contraseña. 
    </div>
    <div class="alert alert-warning" *ngIf="!formPassword.controls['pass'].valid && formPassword.controls['pass'].touched">
        Tu contraseña debe cumplir con los siguientes requerimientos:
        <ul>
            <li>Mínimo de 8 caracteres</li>
            <li>Al menos 1 letra Mayúscula</li>
            <li>Al menos 1 letra minúscula</li>
            <li>Al menos 1 número</li>
        </ul>
    </div>
    <form autocomplete="off" [formGroup]="formPassword" (ngSubmit)="changePassword()" class="px-3 mt-3" id="passwordForm">
        <div class="row mt-2">
            <div class="col-sm-12">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Contraseña</mat-label>
                    <mat-icon matSuffix (click)="hidePass = !hidePass" role="button">{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="pass" maxlength="30">
                    <mat-error *ngIf="!formPassword.valid">Valida el formato de tu contraseña.</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12">
                <mat-form-field class="w-100" color="accent">
                    <mat-label>Confirma contraseña</mat-label>
                    <mat-icon matSuffix (click)="hidePassConfirm = !hidePassConfirm" role="button">{{hidePassConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hidePassConfirm ? 'password' : 'text'" formControlName="passConfirm" maxlength="30">
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button class="btn bg-success text-white" form="passwordForm" type="submit" mat-dialog-close [disabled]="!formPassword.valid">Guardar <mat-icon>save</mat-icon></button>
</mat-dialog-actions>
