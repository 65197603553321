import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { UserModel } from 'src/app/models/users.model';
import { UsersService } from 'src/app/services/users.service';
import { UsersAddComponent } from 'src/app/components/shared/dialogs/users-add/users-add.component';
import { UsersEditComponent } from 'src/app/components/shared/dialogs/users-edit/users-edit.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  private idRoleAdmin = environment.role_admin_id;
  userList = new Array<UserModel>();
  isLoading = true;

  displayedColumns: string[] = ['id', 'role', 'email', 'name', 'actions'];
  dataSource!: MatTableDataSource<UserModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    private matDialog: MatDialog,
    private _titleService: Title
  ) { }

  ngOnInit(): void {
    this.getUsersList();
  }

  getUsersList() {
    this.userS.getUserList().subscribe(
      (result : any) => {
        this.userList = result;
        this.dataSource = new MatTableDataSource(this.userList);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteUser(id:any): void {
    if (this.userS.getUser().id_rol != this.idRoleAdmin) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: '¡No tienes permitido realizar esta acción!',        
      })
    }
    else {
      Swal.fire({
        title: '¿Está seguro que desea eliminar el registro?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'info',
            title: 'Eliminando...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          this.userS.deleteUser(id).subscribe(
            (result:any) => {
              if (result.message == "Success")                
              Swal.fire({
                icon: 'success',
                title: 'Eliminado',
                text: 'Usuario eliminado de manera correcta!'
              }).then(() => {
                this.getUsersList();
              });
            },
            (err:any) => {
              Swal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
              });
            }
          );
        }
      });
    }
  }

  isAdmin() {
    return (this.userS.getUser().id_rol == this.idRoleAdmin);
  }

  activateUser(id: number, active: number, fullname: string, idServ: number) {
    var action = active == 1 ? "desactivado" : "activado";
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.userS.changeStatusUser(id, active == 1 ? 0 : 1, idServ).subscribe(
      (result:any) => {
        if (result.message == "Success")
          Swal.fire({
            icon: 'success',
            title: 'Actualizado',
            text: 'Se ha ' + action + ' a ' + fullname + ' de manera correcta!'
          }).then(() => {
            this.getUsersList();
          });
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar',
          text: 'Ocurrió un error al actualizar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }  

  openDialogUsersCreate() {
    this.matDialog.open(UsersAddComponent, {panelClass: 'dialogs-main'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getUsersList();        
      }
    );
  }

  openDialogUsersEdit(user: UserModel) {
    this.matDialog.open(UsersEditComponent, {
      panelClass: 'dialogs-main',
      data: {
        userSel: user
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getUsersList();        
      }
    );
  }

}
