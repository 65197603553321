import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as chartData from '../shared/data/charts';
import { CampaignModel, CampaignTrackingModel } from 'src/app/models/campaigns.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  isLoading = true;
  campaignList = new Array<CampaignModel>();
  campaignSelected?: CampaignModel;
  campaignData = new Array<CampaignTrackingModel>();
  filteredCampaignData:any;
  processedCampaignData:any;
  pendingBoxesDataSource!: MatTableDataSource<any>;
  campaignDataSource!: MatTableDataSource<any>;
  filterOptions:any;
  fecha_ua!: Date;
  filterSelection:any = {
    zones:  [],
    status: []
  }

  displayedColumns: string[] = ['zona', 'sucursal', 'guia', 'estatus', 'lastUpdate'];
  ShipmentsdisplayedColumns: string[] = ['zona', 'sucursal', 'guia', 'estatus', 'fechaEntrega', 'direccion', 'recibio', 'lastUpdate'];
  estatusMapping:any = {
    'assigned': { label:'Recolectado en centro de distribución', color: 'bg-primary' },
    'order_created': {label:'Orden recibida', color: 'bg-purple'},
    'order_completed': {label: 'Orden surtida', color: 'bg-light-info'},
    'started': {label:'¡En ruta!', color: 'bg-warning'},
    'successful': {label: 'Entregado', color: 'bg-success' },
    'error': {label:'Incidencia', color: 'bg-danger'}
  };
  
  @ViewChild('pendingBoxesPaginator') set pendingBoxesPaginator(value: MatPaginator) { if (this.pendingBoxesDataSource) this.pendingBoxesDataSource.paginator = value; }
  @ViewChild('campaignDataPaginator') set campaignDataPaginator(value: MatPaginator) { if (this.campaignDataSource) this.campaignDataSource.paginator = value; }
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private campaignS: CampaignsService,
    private dashboardS: DashboardService
    ) {}

  ngOnInit(): void {
    this.getCampaignList();
  }

  getCampaignList() {
    this.campaignS.getCampaingsTrackingList().subscribe(
      (result: any) => {
        this.campaignList = result;
        this.setCampaignData(this.campaignList[0]);
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  setCampaignData (campaign:any) {
    this.isLoading = true;
    this.campaignSelected = campaign
    this.fecha_ua = campaign.tracking_last_update;
    this.campaignS.getCampaignData(campaign.id).subscribe(
      (result: any) => {
        this.campaignData = result;
        this.processData(this.campaignData);
        if(result.length > 1) this.initMap(this.campaignData);
        this.pendingBoxesDataSource = new MatTableDataSource(this.campaignData.filter((item: any) => item.estatus !== 'successful'));
        this.campaignDataSource = new MatTableDataSource(this.campaignData);
        this.pendingBoxesDataSource.sort = this.sort;
        this.filterOptions = {
          zonas   : [...new Set(this.campaignData.map((el:any) => el['zona']))],
          estatus : [...new Set(this.campaignData.map((el:any) => el['estatus']))]
        }
        
        this.isLoading = false; 
      }
    );
  }

  processData(campaignData:any) {
    this.processedCampaignData = this.dashboardS.processData(campaignData);
  }

  async initMap(data?:any): Promise<void> {
    const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
    let map = new Map( document.getElementById('map') as HTMLElement, chartData.mapData);
    this.dashboardS.drawMap(data, map);
  }

  parseAddress(address: string) {
    const parsedAddress = JSON.parse(address);
    return parsedAddress ? `${parsedAddress.calle}, ${parsedAddress.colonia}, ${parsedAddress.postal_code}, ${parsedAddress.ciudad}, ${parsedAddress.estado}` : '' ; 
  }

  updateTrackingData(id:number) {
    this.isLoading = true;
    this.campaignS.updateTracking(id.toString()).subscribe(
      (result:any) => {
        if (result.status == "success") {
          this.setCampaignData(this.campaignSelected);
        }
        this.isLoading = false;
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  formatDate(date: string) {
    if(!date) return 
    const fechaEntrega = date.replace(/undefined-/g, '');
    const fecha = fechaEntrega.split(' ');
    const sinHora = fecha[0];
    const fechaObj = new Date(sinHora);
    const anio = fechaObj.getFullYear();
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const dia = fechaObj.getUTCDate().toString().padStart(2, '0');

    return `${anio}-${mes}-${dia}`;
  }

  filterCampaign($event: any, zones:any, status:any ) {
    $event.stopPropagation();
    $event.preventDefault();
    if(zones.length < 1){
      this.setTemporaryCampaignData(this.campaignData)
    }else{
      this.filterSelection.zones = zones.map((o:any) => o.value);
      this.filterSelection.status = status.map((o:any) => o.value);
      this.setTemporaryCampaignData();
    }
  }

  setTemporaryCampaignData (data?:any) {
    if(data){
      this.processData(data);
      this.initMap(data);
      this.pendingBoxesDataSource = new MatTableDataSource(data.filter((item: any) => item.estatus !== 'successful'));
      this.campaignDataSource = new MatTableDataSource(data);
  
      this.isLoading = false;
    }else{
      this.filteredCampaignData = this.campaignData.filter((el:any) => ( this.filterSelection.zones.includes(el.zona) && this.filterSelection.status.includes(el.estatus) ));
      this.isLoading = true;
      this.processData(this.filteredCampaignData);
      this.initMap(this.filteredCampaignData);
      this.pendingBoxesDataSource = new MatTableDataSource(this.filteredCampaignData.filter((item: any) => item.estatus !== 'successful'));
      this.campaignDataSource = new MatTableDataSource(this.filteredCampaignData);
  
      this.isLoading = false;
    }
  }
 
}
