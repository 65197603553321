import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { CampaignModel } from 'src/app/models/campaigns.model';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { CampaignsAddComponent } from '../shared/dialogs/campaigns-add/campaigns-add.component';
import Swal from 'sweetalert2';
import { CampaignsEditComponent } from '../shared/dialogs/campaigns-edit/campaigns-edit.component';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/app/services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {

  private idRoleAdmin = environment.role_admin_id;
  campaignList = new Array<CampaignModel>();
  isLoading = true;
  quality!: number;
  displayQuality: any[] = [{ quality: 480, item: "sd" }, { quality: 720, item: "hd" }, { quality: 1080, item: "hd" }];
  displayedColumns: string[] = ['id', 'compania', 'name', 'created_at', 'file', 'actions'];
  dataSource!: MatTableDataSource<CampaignModel>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private userS: UsersService,
    private campaignS: CampaignsService,
    private _router: Router,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getConfigCampaings();
    this.getCampaingsList();
  }
  
  getConfigCampaings(){
    this.campaignS.getCampaignsConfig().subscribe(
      (result : any) => {
        const config = result.data;
        this.quality = config.calidad_video;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  getCampaingsList(){
    this.campaignS.getCampaingList().subscribe(
      (result : any) => {
        this.campaignList = result;
        this.dataSource = new MatTableDataSource(this.campaignList);        
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      },
      (err : any) => {
        console.log(err);
        this.isLoading = false;
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogCampaignsCreate() {
    this.matDialog.open(CampaignsAddComponent, {panelClass: 'dialogs-sm'}).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getCampaingsList();        
      }
    );
  }

  activateCampaign(id: number, active: number, name: string) {
    var action = active == 1 ? "desactivada" : "activada";
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.campaignS.changeStatusCampaign(id, active == 1 ? 0 : 1).subscribe(
      (result:any) => {
        if (result.message == "Success") {
          Swal.fire({
            icon: 'success',
            title: 'Actualizado',
            text: 'Campaña ' + name + ' ' + action + ' de manera correcta!'
          }).then(() => {
            this.getCampaingsList();
          });
        }
        else if(result.message == "invalid") {
          Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: 'No es posible activar la campaña ' + name + ' porque algunas de sus estaciones ya se encuentran activas en otra campaña '
          }).then(() => {
            this.getCampaingsList();
          });
        }
      },
      (err:any) => {
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar',
          text: 'Ocurrió un error al actualizar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  
  activateCloudVideo(id: number, active: number) {
    var action = active == 1 ? "desactivada" : "activada";
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.campaignS.changeActiveCloudVideo(id, active == 1 ? 0 : 1).subscribe(
      (result:any) => {
        if (result.message == "Success")
          Swal.fire({
            icon: 'success',
            title: 'Actualizado',
            text: 'Guardado en la nube ' + action + ' de manera correcta!'
          }).then(() => {
            this.getCampaingsList();
          });
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar',
          text: 'Ocurrió un error al actualizar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  
  editQVideo(q: number) {
    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.campaignS.changeQVideo(q).subscribe(
      (result:any) => {
        if (result.message == "Success")
          Swal.fire({
            icon: 'success',
            title: 'Actualizado',
            text: 'Cambio la calidad de video a ' + q + 'p de manera correcta!'
          }).then(() => {
            this.quality = q;
          });
      },
      (err:any) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Error al actualizar',
          text: 'Ocurrió un error al actualizar el registro, por favor vuelva a intentarlo.'
        });
      }
    );
  }

  openDialogCampaignEdit(campaign: CampaignModel) {
    this.matDialog.open(CampaignsEditComponent, {
      panelClass: 'dialogs-sm',
      data: {
        campaignSel: campaign
      }
    }).afterClosed().subscribe(
      resp => {        
          this.isLoading = true;
          this.getCampaingsList();        
      }
    );
  }

  deleteCampaign(id:any): void {
    if (this.userS.getUser().id_rol != this.idRoleAdmin) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: '¡No tienes permitido realizar esta acción!',        
      })
    }
    else {
      Swal.fire({
        title: '¿Está seguro que desea eliminar la campaña?',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Confirmar`,
        cancelButtonText: `Cancelar`
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'info',
            title: 'Eliminando...',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            didOpen: () => {
              Swal.showLoading();
            }
          });
          this.campaignS.deleteCampaign(id).subscribe(
            (result:any) => {
              if (result.message == "Success")                
              Swal.fire({
                icon: 'success',
                title: 'Eliminado',
                text: 'Campaña eliminada de manera correcta!'
              }).then(() => {
                this.getCampaingsList();
              });
            },
            (err:any) => {
              Swal.fire({
                icon: 'error',
                title: 'Error al eliminar',
                text: 'Ocurrió un error al eliminar el registro, por favor vuelva a intentarlo.'
              });
            }
          );
        }
      });
    }
  }

  goToCampaign(id: number) {
    this._router.navigateByUrl('/admin/distribution/' + id)
  }

  isAdmin() {
    return (this.userS.getUser().id_rol == this.idRoleAdmin);
  }

}
