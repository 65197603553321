<div>
    <div class="logo-wrapper">
        <a [routerLink]="'dashboard'">
            <img class="img-fluid  for-dark" src="./../../../../assets/images/logo.png" alt="">
        </a>
    </div>
    <nav class="sidebar-main">
        <div id="sidebar-menu">
            <ul class="sidebar-links">
                <div class="simplebar-wrapper">
                    <div class="simplebar-height-auto-observer-wrapper">
                        <div class="simplebar-height-auto-observer"></div>
                    </div>
                    <div class="simplebar-mask">
                        <div class="simplebar-offset">
                            <div class="simplebar-content-wrapper">
                                <div class="simplebar-content">
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'dashboard'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">dashboard</mat-icon> Dashboard</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'dashboardpnp'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">barcode_reader</mat-icon> Dashboard P&P</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'campaign'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">campaign</mat-icon> Campañas</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'materials'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">category</mat-icon> Materiales</span>
                                        </a>
                                    </li>
                                    <li class="sidebar-list">
                                        <a class="sidebar-link sidebar-title" [routerLink]="'users'" [routerLinkActive]="'active'">
                                            <span><mat-icon class="align-middle me-2">group</mat-icon> Usuarios</span>
                                        </a>
                                    </li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </nav>
</div>