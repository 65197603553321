<!-- login page start-->
<div class="container-fluid p-0">
    <div class="row m-0">
        <div class="col-12 p-0">
            <div class="login-card login-dark">
                <div>
                    <div><a class="logo" href="index.html"><img class="img-fluid for-light"
                                src="/assets/images/logo_claw.png" alt="looginpage">
                            <img class="img-fluid for-dark" src="/assets/images/logo_claw.png"
                                alt="looginpage"></a></div>
                    <div class="login-main">
                        <form (ngSubmit)="login(f)" #f="ngForm" autocomplete="off">
                            <h4>Hola, somos Búho Claw 👋</h4>
                            <p>Por favor inicia sesion en tu cuenta</p>
                            <div>
                                <mat-form-field class="w-100" color="accent">
                                    <mat-label>Usuario</mat-label>
                                    <input matInput placeholder="Usuario" [(ngModel)]="user.email" name="user" required>
                                    <mat-icon matSuffix>account_circle</mat-icon>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="w-100" color="accent">
                                    <mat-label>Contraseña</mat-label>
                                    <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" [(ngModel)]="user.pass" name="pass" required>
                                    <mat-icon matSuffix (click)="hide = !hide" role="button">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-checkbox [(ngModel)]="rememberme" name="rememberme" color="accent">
                                    Recordarme
                                </mat-checkbox>
                            </div>
                            <div>
                                <button mat-raised-button color="warn" class="w-100 mt-2" type="submit">Iniciar sesión</button>
                            </div>
                        </form>
                        <div class="mt-2 text-center">
                            <a role="button" (click)="openDialog()">Olvidé mi contraseña</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>