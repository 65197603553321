<div class="modal-header">
    <h4 class="modal-title">Material: <strong>{{material_info}}</strong></h4>
    <button type="button" class="btn-close" mat-dialog-close></button>
</div>
<mat-dialog-content>
    <div class="modal-body">
        <div class="row mb-4" style="background-color: #0000001A;">
            <div class="keen-slider keep-cont" #sliderRef>
                <div class="keen-slider__slide num-s" *ngFor="let imga of imageObject;">
                    <img class="center-image img-view" [src]="imga.image" alt="Material file" />
                </div>
              </div>

            <!-- <div class="keen-slider" #sliderRef>
                <div [class]="'keen-slider__slide number-slide' + i" *ngFor="let imga of imageObject; index as i;">
                    <img [src]="imga.image" alt="Material file" />
                </div>
            </div> -->
            <!-- <ngb-carousel> 
                <ng-template ngbSlide *ngFor="let imga of imageObject">
                    <div class="picsum-img-wrapper p-5">
                        <img class="center img-view" [src]="imga.image" alt="Material file" />
                    </div>
                </ng-template>
            </ngb-carousel> -->
        </div>
    </div>
</mat-dialog-content>
<div class="modal-footer pt-4">

</div>